import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { priceToFixed } from 'helpers/priceToFixed';
import TableHelper from 'cms/components/Table/TableHelper';
import TableRow from 'cms/components/Table/TableRow';
import TableCell from 'cms/components/Table/TableCell';
import Checkbox from 'cms/components/Checkbox';
import messages from '../messages';
import routes from 'routes';
import MobileTableExpandButton from 'cms/components/Table/Mobile/MobileTableExpandButton';

const headerItems = [
  messages.password,
  messages.name,
  messages.category,
  messages.entry_price,
  messages.web_price
];

const Table = ({
  data,
  ids,
  setIDs,
  checkAll,
  setCheckAll,
  productsIds,
  handleDisconnectProduct
}) => {
  const [opened, setOpened] = useState([]);
  const link = item => `${routes.CMS_PRODUCTS}/${item.id}`;

  console.log('THE SELECTED IDS ARE: ', ids);

  const handleClick = id => {
    if (ids.includes(id)) {
      setCheckAll(false);
      setIDs(ids.filter(item => item !== id));
    } else {
      setIDs([...ids, id]);
    }
  };

  const handleCheckAll = () => {
    setCheckAll(!checkAll);
    if (!checkAll) setIDs(productsIds);
    if (checkAll) setIDs([]);
  };

  return (
    <TableHelper
      tableName="eoffer"
      tableHeaders={headerItems}
      checkboxValue={checkAll}
      setCheckboxValue={handleCheckAll}
    >
      {data.map(item => (
        <TableRow key={item.id}>
          <MobileTableExpandButton
            id={item.id}
            opened={opened}
            setOpened={setOpened}
          />
          <TableCell>
            <Checkbox
              checked={checkAll ? checkAll : ids.includes(item.id)}
              onClick={() => handleClick(item.id)}
            />
          </TableCell>
          <TableCell classes="p15-0">
            <Link to={link(item)}>{item.sku}</Link>
          </TableCell>
          <TableCell classes="p15-0">
            <Link to={link(item)}>{item.name}</Link>
          </TableCell>
          <TableCell>
            {item.mainCategory && item.mainCategory.length
              ? item.mainCategory.map(category => (
                  <p key={category.id}>{category.name}</p>
                ))
              : null}
          </TableCell>
          <TableCell>{priceToFixed(item.entry_price)}</TableCell>
          <TableCell>{priceToFixed(item.web_price)}</TableCell>
        </TableRow>
      ))}
    </TableHelper>
  );
};

export default Table;