import React from 'react';
import { useIntl } from 'react-intl';
import { FiTruck } from 'react-icons/all';

import FrontendDropdown from './FrontendDropdown';
import PricesDropdown from './PricesDropdown';
import LinkingDropdown from './LinkingDropdown';
import InvoicesDropdown from './InvoicesDropdown';
import NavLink from './NavLink';
import { ungrouped } from './groups';
import messages from './messages';
import routes from 'routes';
import { FaBookmark } from 'react-icons/all';
const Content = ({ toggle }) => {
  const { formatMessage } = useIntl();

  return (
    <div className="content">
      {ungrouped.map((item, index) => (
        <NavLink
          key={index}
          name={item.name}
          icon={item.icon}
          route={item.url}
          role={item.role}
          module={item.module}
          closeSidebar={toggle}
        />
      ))}
      {/* <li>
        <a href="https://db.jakovsmartsolutions.com/login.php" target="_blank">
          <FiTruck />
          <span>{formatMessage(messages.suppliers)}</span>
        </a>
      </li> */}
      <NavLink
        key="catalog-stickers"
        name={messages.catalog_stickers}
        icon={<FaBookmark/>}
        route={routes.CMS_CATALOG_STICKERS}
        closeSidebar={toggle}
      />
      <FrontendDropdown closeSidebar={toggle} />
      <PricesDropdown closeSidebar={toggle} />
      <LinkingDropdown closeSidebar={toggle} />
      <InvoicesDropdown closeSidebar={toggle} />
    </div>
  );
};

export default Content;
