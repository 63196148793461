import { takeLatest, call, put, select } from 'redux-saga/effects';
import request from 'utils/request';
import { enqueueSnackbar } from 'containers/Notifier/actions';
import {
  getProducts,
  setProducts,
  updateDescriptionSuccess,
  massActivateAttributesSuccess,
  massDeactivateAttributesSuccess,
  disconnectProductSuccess
} from './actions';
import types from './constants';
import { makeSelectParams } from './selectors';
import parseApiErrorsToFormik from 'utils/parseApiErrorsToFormik';

const BASE_URL = 'admin/products';

export function* fetchProducts({ payload }) {
  try {
    let tmpPayload = payload;
    // let manufacturers = []
    // if(payload.manufacturers && payload.manufacturers){
    //   payload.manufacturers.forEach(element => {
    //     manufacturers.push(element.id)
    //   });
    //   tmpPayload = {
    //     ...tmpPayload,
    //     manufacturers: manufacturers.toString()
    //   }
    // }
    const response = yield call(request, {
      url: `${BASE_URL}?include=mainCategory&visible=1&active=1&eponuda=0`,
      method: 'get',
      params: tmpPayload
    });
    console.log('SAGA RESPONSE IS: ', response);
    yield put(setProducts(response));
  } catch (error) {}
}

export function* updateDescriptions({ params, payload, close, clear }) {
  try {
    const res = yield call(request, {
      url: `${BASE_URL}/mass-update`,
      method: 'patch',
      params,
      data: payload
    });

    yield put(updateDescriptionSuccess());
    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
    yield clear();
  } catch (error) {}
}

export function* handleMassActivateAttributes({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/attributes/mass-activate`,
      method: 'post',
      data: payload
    });

    yield put(massActivateAttributesSuccess());
  } catch (error) {}
}

export function* handleMassDectivateAttributes({ payload }) {
  try {
    yield call(request, {
      url: `${BASE_URL}/attributes/mass-deactivate`,
      method: 'delete',
      data: payload
    });

    yield put(massDeactivateAttributesSuccess());
  } catch (error) {}
}

export function* disconnectProduct({ id, close }) {
  try {
    const res = yield call(request, {
      url: `admin/pricelist-products/${id}/connect`,
      method: 'delete'
    });

    yield put(disconnectProductSuccess());
    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
  } catch (error) {}
}

export function* massUpdateDiscounts({
  payload,
  params,
  close,
  clear,
  meta: { setErrors }
}) {
  try {
    const res = yield call(request, {
      url: `admin/discounts/mass-add`,
      method: 'post',
      data: payload,
      params: !payload.product_ids.length && params
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
    let productParams = yield select(makeSelectParams());
    yield put(getProducts(productParams));
    yield close();
    yield clear();
  } catch (error) {
    if (error.status === 422)
      yield call(setErrors, parseApiErrorsToFormik(error.data.errors));
  }
}
export function* handleUploadStiker({ payload }) {
  try {
    const formData = new FormData();
    formData.append('ids[]', payload.ids);
    formData.append('sticker', payload.sticker);
    yield call(request, {
      url: `${BASE_URL}/energy-sticker/mass-update`,
      method: 'post',
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    // yield put(massActivateAttributesSuccess());
  } catch (error) {}
}
export function* handleUploadAction({ payload, params }) {
  try {
    const data = {
      catalog_sticker_type_id: payload.catalog_sticker_type_id,
      from: payload.from,
      ids: payload.product_ids,
      to: payload.to
    };
    const res = yield call(request, {
      url: `admin/catalog-stickers/mass-add`,
      method: 'post',
      data,
      params: !data.ids.length && params
    });

    yield put(enqueueSnackbar({ message: res.data.message }));
  } catch (error) {}
}

export default function* visibleOnSiteSaga() {
  yield takeLatest(types.GET_PRODUCTS, fetchProducts);
  yield takeLatest(types.UPDATE_DESCRIPTIONS, updateDescriptions);
  yield takeLatest(
    types.MASS_ACTIVATE_ATTRIBUTES_REQUEST,
    handleMassActivateAttributes
  );
  yield takeLatest(
    types.MASS_DEACTIVATE_ATTRIBUTES_REQUEST,
    handleMassDectivateAttributes
  );
  yield takeLatest(types.DISCONNECT_PRODUCT, disconnectProduct);
  yield takeLatest(types.MASS_UPDATE_DISCOUNTS, massUpdateDiscounts);
  yield takeLatest(types.UPLOAD_STICKER, handleUploadStiker);
  yield takeLatest(types.UPLOAD_ACTION, handleUploadAction);
}