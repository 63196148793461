import React from 'react';
import { Helmet } from 'react-helmet-async';

const PageTitle = ({ name }) => {
  return (
    <Helmet>
      <title>{name} - Admin Panel | Frog</title>
    </Helmet>
  );
};
export default PageTitle;
